import { zeroAddress } from 'viem';
import { useChainId, useReadContract, UseReadContractReturnType } from 'wagmi';
import { IfasstUpgradeableABI, IfasstUpgradeableDeploymentDetails } from '../constants/contractDeploymentDetails/IfasstUpgradeable';
import {
    StructReturnsAnalyticsAndDefaultsStructOutput,
    StructUserAccountReturnStructOutput
} from '../../web3/contracts/typechain-types/contracts/IfasstUpgradeable';

export const useReadStakingAndReferralContract = (
    functionName:
        "owner" | "UPGRADE_INTERFACE_VERSION" | "getContractAnalytics" | "getInvestmentPlanById" | "getUserAccount" | "pendingOwner" | "proxiableUUID" | "getSupportedToken" | "getVariablesContract" | undefined,
    args:
        readonly [] | readonly [`0x${string}`] | readonly [bigint] | undefined
) => {
    const chainId = useChainId();
    const contractObject = IfasstUpgradeableDeploymentDetails[chainId];

    // @ts-ignore
    const result = useReadContract({
        abi: IfasstUpgradeableABI,
        address: contractObject?.proxyAddress as `0x${string}`,
        functionName: functionName,
        args: args,
        blockTag: 'latest'
    });

    return result;
};

export type UseReadContractWithUserAccountType = Omit<
    UseReadContractReturnType,
    'data'
> & {
    data: StructUserAccountReturnStructOutput | undefined;
};

export const useGetUserAccount = (address: `0x${string}` | undefined) => {
    address = address ?? zeroAddress;
    const result = useReadStakingAndReferralContract('getUserAccount', [
        address
    ]) as UseReadContractWithUserAccountType;

    return result;
};

export const useGetContractAnalytics = () => {
    let result = useReadStakingAndReferralContract('getContractAnalytics', []);
    const resultWithType = {
        ...result,
        data: result?.data
    } as
        | (typeof result & {
            data: StructReturnsAnalyticsAndDefaultsStructOutput;
        })
        | undefined;

    return resultWithType;
};

// export const useGetUserPendingStakingRewards = (
//     address: `0x${string}` | undefined
// ) => {
//     let result = useReadStakingAndReferralContract('getUserStakingsReward', [
//         address ?? zeroAddress
//     ]);

//     const resultWithType = {
//         ...result,
//         data: result?.data
//     } as
//         | (typeof result & {
//             data: bigint;
//         })
//         | undefined;

//     return resultWithType;
// };

// export const useGetStakingById = (stakingId: bigint) => {
//     let result = useReadStakingAndReferralContract('getStakingAccountById', [
//         stakingId
//     ]);

//     const resultWithType = {
//         ...result,
//         data: result?.data
//     } as
//         | (typeof result & {
//             data: [StructStakingStructOutput, bigint] & {
//                 stakingAccount: StructStakingStructOutput;
//                 pendingReward: bigint;
//             };
//         })
//         | undefined;

//     return resultWithType;
// };
