import { useEffect } from 'react';
import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";

export const CookiesPoly = () => {

    return (
        <Flex w="full" gap={10} direction="column" px={5} py={5}>
            <Heading>
                Cookies Policy for iFasst - Web3 Technology
            </Heading>
            <Text>
                At iFasst, we are committed to enhancing your experience on our decentralized platform, which utilizes cutting-edge Web3 technology. This Cookies Policy outlines how we use cookies and similar tracking technologies to optimize functionality, improve user interaction, and ensure security within our ecosystem. By using iFasst, you consent to the use of cookies as described in this policy. If you do not agree, you can manage your cookie preferences through your browser settings.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                1. Understanding Cookies
            </Heading>
            <Text size="md" mb={-4}>
                Cookies are small text files stored on your device when you visit a website. They allow us to remember information about your visit, enhancing your browsing experience. In the context of Web3 technology, cookies help facilitate transactions, manage digital assets, and secure user interactions with decentralized applications (dApps).
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                . Types of Cookies Used by iFasst
            </Heading>
            <Text size="md" mb={-7}>
                iFasst employs various types of cookies to ensure a smooth, secure, and personalized user experience:
            </Text>
            <UnorderedList mb={4}>
                <ListItem>
                    <b>Essential Cookies:</b> Essential cookies are crucial for the core functionalities of the iFasst platform. They enable secure wallet connections, authenticate user sessions, and facilitate transactions. <br />
                    Examples: Cookies that manage user logins and secure interactions with smart contracts.
                </ListItem>
                <ListItem>
                    <b>Functional Cookies:</b> These cookies remember user preferences and settings to enhance the overall experience. They allow iFasst to provide customized interactions tailored to your needs. <br />
                    Examples: Cookies that save your preferred staking options or interface settings.
                </ListItem>
                <ListItem>
                    <b>Performance and Analytics Cookies:</b> Performance and analytics cookies collect data on how users engage with the platform, helping us improve our services and understand user behavior. <br />
                    Examples: Cookies from analytics tools that track usage patterns and user flows on the platform.
                </ListItem>
                <ListItem>
                    <b>Advertising and Targeting Cookies:</b> These cookies may be used to deliver personalized content and advertisements based on your browsing habits within the Web3 ecosystem. <br />
                    Examples: Cookies used by third-party advertising networks that track user interactions across various platforms.
                </ListItem>
                <ListItem>
                    <b>Security Cookies:</b> Security cookies play a vital role in protecting our platform and its users from fraudulent activities. They help verify user identities and detect any suspicious behavior. <br />
                    Examples: Cookies that monitor transaction patterns to prevent unauthorized access.
                </ListItem>
            </UnorderedList>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                3. Why We Use Cookies in Web3
            </Heading>
            <Text mb={-7}>
                The use of cookies at iFasst is essential for the following reasons:
            </Text>
            <UnorderedList mb={-4}>
                <ListItem>Enhancing User Experience: Cookies help create a more personalized experience on our platform by remembering your preferences and streamlining interactions. This is particularly important in a decentralized environment where user experience can vary significantly.</ListItem>
                <ListItem>Facilitating Transactions: In a Web3 context, cookies enable smooth transactions by storing necessary data for user authentication, wallet connections, and interactions with smart contracts. This allows for a frictionless experience when staking or managing digital assets.</ListItem>
                <ListItem>Improving Performance: By analyzing data collected through cookies, we can identify trends, performance bottlenecks, and user pain points. This information is vital for continuously improving the platform and ensuring it meets the needs of our users.</ListItem>
                <ListItem>Ensuring Security: Security is paramount in the Web3 space. Cookies help us detect and respond to potential threats, safeguarding your assets and information from unauthorized access or malicious activities.</ListItem>
            </UnorderedList>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                4. Third-Party Cookies
            </Heading>
            <Text mb={-4}>
                iFasst may also utilize third-party services, such as analytics providers and advertising networks, which may place cookies on your device. These cookies are governed by the respective privacy policies of these third parties. While we strive to partner with reputable providers, we encourage users to review their cookie and privacy policies to understand how they handle your data.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                5. Managing Your Cookie Preferences
            </Heading>
            <Text mb={-7}>
                You have control over how cookies are managed on your device. Most web browsers allow you to:
            </Text>
            <UnorderedList mb={-7}>
                <ListItem>Accept or Decline Cookies: You can configure your browser to block all cookies or accept them by default.</ListItem>
                <ListItem>Delete Existing Cookies: You can clear cookies stored on your device, although this may affect your experience on iFasst.</ListItem>
                <ListItem>Customize Cookie Settings for Specific Sites: Some browsers offer settings that allow you to manage cookies on a site-by-site basis.</ListItem>
            </UnorderedList>
            <Text mb={-4}>
                To manage cookies effectively, refer to your browser's help section for specific instructions.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                6. Cookies Consent
            </Heading>
            <Text mb={-4}>
                By using iFasst, you consent to the use of cookies as outlined in this policy. A cookie consent banner will appear when you first visit our platform, giving you the option to accept or customize your cookie preferences. You can withdraw your consent at any time by adjusting your browser settings.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                7. Data Protection and Privacy
            </Heading>
            <Text mb={-4}>
                At iFasst, we take your privacy seriously. We comply with applicable data protection laws, including the General Data Protection Regulation (GDPR) and other relevant regulations. While cookies help us enhance user experience, we do not collect personally identifiable information (PII) unless you provide it voluntarily through forms or interactions.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                8. Security Measures
            </Heading>
            <Text mb={-4}>
                We implement security measures to protect your data and ensure that cookies do not pose risks to your personal information. However, we encourage users to adopt good online security practices, such as using secure connections and regularly clearing cookies.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                9. Changes to the Cookies Policy
            </Heading>
            <Text mb={-4}>
                iFasst reserves the right to modify this Cookies Policy at any time. We will notify users of any significant changes through our platform, and the "Last Updated" date will reflect the most recent revision. We encourage you to periodically review this policy to stay informed about our cookie usage.
            </Text>

            <Heading size="md" mb={-7}> {/* Set bottom margin here */}
                10. Contact Information
            </Heading>
            <Text mb={-4}>
                If you have any questions or concerns regarding our Cookies Policy or the use of cookies on iFasst, please contact our support team. We are here to assist you and clarify any aspects of our cookie practices.
            </Text>

            <Text mt={6}>Last Updated: October 6, 2024</Text>
        </Flex>


    );
};