import { createHashRouter } from 'react-router-dom';
import { lazy } from 'react';
import { ProtectedRoutes } from './ProtectedRoutes';
import { AllStats } from '../pages/dashboard/allstats/AllStats';
import { Transactions } from '../pages/dashboard/transactions/Transactions';
import StakingsDashboard from '../pages/dashboard/stakings/StakingsDashboard';
import { Disclaimer } from '../pages/disclaimer/Disclaimer';
import { AboutUs } from '../pages/aboutUs/AboutUs';
import { PrivacyPolicy } from '../pages/privacyPolicy/PrivacyPolicy';
import { CookiesPoly } from '../pages/cookiesPoly/CookiesPoly';
import { TermsAndCondition } from '../pages/termsandcondition/TermsAndCondition';
import { Roadmap } from '../pages/home/roadmap/Roadmap';

const App = lazy(() => import('../App'));
const Home = lazy(() => import('../pages/home/Home'));
const Register = lazy(() => import('../pages/register/Register'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const TeamDashboard = lazy(
  () => import('../pages/dashboard/team/TeamDashboard'),
);
const IncomeDashboard = lazy(
  () => import('../pages/dashboard/income/IncomeDashboard'),
);
const BusinessDashboard = lazy(
  () => import('../pages/dashboard/business/BusinessDashboard'),
);

export const NavigationRoutes = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'Register/:referrerAddress?',
        element: (
          <ProtectedRoutes>
            <Register />
          </ProtectedRoutes>
        ),
      },
      {
        path: 'Dashboard/:userAddress?',
        element: (
          <ProtectedRoutes>
            <Dashboard />
          </ProtectedRoutes>
        ),
        children: [
          {
            index: true,
            element: <AllStats />,
          },
          {
            path: 'AllStats',
            index: true,
            element: <AllStats />,
          },
          {
            path: 'StakingsDashboard',
            index: true,
            element: <StakingsDashboard />,
          },
          {
            path: 'BusinessDashboard',
            element: <BusinessDashboard />,
          },
          {
            path: 'IncomeDashboard',
            element: <IncomeDashboard />,
          },
          {
            path: 'TeamDashboard',
            element: <TeamDashboard />,
          },
          {
            path: 'Transactions',
            element: <Transactions />,
          },
        ],
      },
      {
        path: 'disclaimer',
        element: (
          <Disclaimer />
        ),
      },
      {
        path: 'about-us',
        element: (
          <AboutUs />
        ),
      },
      {
        path: 'privacy-policy',
        element: (
          <PrivacyPolicy />
        ),
      },
      {
        path: 'roadmap',
        element: (
          <Roadmap />
        ),
      },
      {
        path: 'cookiespoly',
        element: (
          <CookiesPoly />
        ),
      },
      {
        path: 'terms',
        element: (
          <TermsAndCondition />
        ),
      },
    ],
  },
  {
    path: '*',
    element: <App />,
  },
]);
