import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spacer,
  Tag,
  Text,
  VStack,
  Wrap
} from "@chakra-ui/react";
import {
  FcConferenceCall,
  FcDoughnutChart,
  FcElectricalSensor,
  FcFeedIn
} from "react-icons/fc";
import {
  HiOutlineUserGroup,
  HiOutlineUsers,
  HiUser,
  HiUserGroup,
  HiUsers
} from "react-icons/hi2";
import { LuPalmtree } from "react-icons/lu";
import { RiRobot2Fill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { formatEther } from "viem";
import { useAccount } from "wagmi";
import DashboardItemContainer from "../../../components/DashboardItemContainer";
import { UserAllBalancesComponent } from "../../../components/UserAllBalancesComponent";
import {
  useGetContractAnalytics,
  useGetUserAccount,
  UseReadContractWithUserAccountType
} from "../../../hooks/referralHooks";
import { ReferralLinkComponent } from "../team/TeamDashboard";
import {
  useGetStakingUserAccount,
  useGetUserPendingStakingRewards
} from "../../../hooks/stakingHooks";
import { IfasstStakingUpgradeableABI } from "../../../constants/contractDeploymentDetails/IfasstStakingUpgradeable";
import { StructStakingStructOutput } from "../../../../web3/contracts/typechain-types/contracts/IfasstUpgradeable";
import { TbShieldLock } from "react-icons/tb";
import {
  CircleDotDashed,
  Shield,
  ShieldCheck,
  SquareCheckBig
} from "lucide-react";
import { FaClipboardUser } from "react-icons/fa6";

export const BalancesComponent = () => {
  return <UserAllBalancesComponent />;
};

export const BusinessComponent = ({
  userAccount
}: {
  userAccount: UseReadContractWithUserAccountType;
}) => {
  const dataArray = [
    {
      label: "Total Deposits",
      valueInUSD: formatEther(
        userAccount?.data?.business?.selfBusiness ?? BigInt(0)
      ),
      icon: HiUser
    },
    {
      label: "Direct Team Deposits",
      valueInUSD: formatEther(
        userAccount?.data?.business?.directBusiness ?? BigInt(0)
      ),
      icon: HiUsers
    },
    {
      label: "Total Team Deposits",
      valueInUSD: formatEther(
        userAccount?.data?.business?.teamBusiness ?? BigInt(0)
      ),
      icon: HiUserGroup
    }
  ];
  return (
    <DashboardItemContainer
      heading="Business"
      icon={FcFeedIn}
      style={{
        w: "full",
        maxW: 400
      }}
    >
      {dataArray?.map((datasObject, key) => {
        return (
          <Tag
            as={Flex}
            w="full"
            borderWidth="thin"
            p={5}
            borderRadius="3xl"
            colorScheme="twitter"
          >
            <Flex direction="column" gap={2}>
              <Heading size="sm">{datasObject?.label}</Heading>
              <Text>$ {datasObject?.valueInUSD} USD</Text>
            </Flex>
            <Spacer />
            <Icon as={datasObject?.icon} boxSize={8}></Icon>
          </Tag>
        );
      })}
    </DashboardItemContainer>
  );
};

export const TeamComponent = ({
  userAccount
}: {
  userAccount: UseReadContractWithUserAccountType;
}) => {
  const dataArray = [
    {
      label: "Direct Team",
      value: Number(userAccount?.data?.referees?.length ?? 0),
      icon: HiUsers
    },
    {
      label: "Total Team",
      value: Number(userAccount?.data?.teams?.length ?? 0),
      icon: HiUserGroup
    }
  ];
  return (
    <DashboardItemContainer heading="Teams" icon={FcConferenceCall}>
      {dataArray?.map((datasObject, key) => {
        return (
          <Tag
            as={Flex}
            w="full"
            borderWidth="thin"
            p={5}
            borderRadius="3xl"
            colorScheme="twitter"
          >
            <Flex direction="column" gap={2}>
              <Heading size="sm">{datasObject?.label}</Heading>
              <Text>{datasObject?.value} Users</Text>
            </Flex>
            <Spacer />
            <Icon as={datasObject?.icon} boxSize={8}></Icon>
          </Tag>
        );
      })}
    </DashboardItemContainer>
  );
};

export const IncomeComponent = ({
  userAccount
}: {
  userAccount: UseReadContractWithUserAccountType;
}) => {
  const userPendingStakingRewards = useGetUserPendingStakingRewards(
    userAccount?.data?.userAddress as `0x${string}`
  );
  const stakingContractABI = IfasstStakingUpgradeableABI;

  const userStakingAccount = useGetStakingUserAccount(
    userAccount?.data?.userAddress as `0x${string}`
  );
  const stakingsArray = userStakingAccount?.data?.stakings;

  const totalStakingValue = stakingsArray?.reduce(
    (totalValue: number, staking: StructStakingStructOutput) =>
      totalValue +
      Number(
        formatEther(
          staking?.isActive
            ? staking?.valueInUSD
            : (BigInt(0) ?? BigInt(0))
        )
      ),
    0
  );

  console.log("userStakingAccount", userStakingAccount);

  const dataArray = [
    {
      label: "Total Staked Value",
      valueInUSD: totalStakingValue?.toFixed(2),
      icon: ShieldCheck,
      pre: "$",
      post: "USD"
    },
    {
      label: "Staking Rewards Claimed",
      valueInUSD: Number(
        formatEther(
          userStakingAccount?.data?.incomes?.staking ?? BigInt(0)
        )
      )?.toFixed(7),
      icon: SquareCheckBig,
      pre: "$",
      post: "USD"
    },
    {
      label: "Pending Staking Rewards",
      valueInUSD: formatEther(
        userPendingStakingRewards?.data ?? BigInt(0)
      ),
      icon: CircleDotDashed,
      pre: "$",
      post: "USD"
    },
    {
      label: "Tier Rewards",
      valueInUSD: formatEther(
        userAccount?.data?.incomes?.referral ?? BigInt(0)
      ),
      icon: FaClipboardUser,
      pre: "$",
      post: "USD"
    },
    {
      label: "Direct Team",
      valueInUSD: Number(
        userAccount?.data?.referees?.length ?? BigInt(0)
      ),
      icon: HiOutlineUsers,
      pre: "",
      post: "Users"
    },
    {
      label: "Total Team",
      valueInUSD: Number(userAccount?.data?.teams?.length ?? BigInt(0)),
      icon: HiOutlineUserGroup,
      pre: "",
      post: "Users"
    }
  ];
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} w="full">
      {dataArray?.map((datasObject, key) => (
        <Flex
          key={key}
          direction="column"
          w="full"
          borderWidth="thin"
          p={5}
          borderRadius="3xl"
          gap={5}
        >
          <Flex direction="column" gap={2}>
            <Heading size="sm">{datasObject?.label}</Heading>
            <Text fontSize={"xl"} fontWeight={700}>
              {datasObject?.pre} {datasObject?.valueInUSD}{" "}
              {datasObject?.post}
            </Text>
          </Flex>
          <HStack w="full">
            <Spacer />
            <Icon as={datasObject?.icon} boxSize={16}></Icon>
          </HStack>
        </Flex>
      ))}
    </SimpleGrid>
  );
};

export const AllStats = () => {
  const { address } = useAccount();
  const { userAddress } = useParams<{
    userAddress: `0x${string} ` | undefined;
  }>();

  const currentUser = userAddress ?? address;
  const userAccount = useGetUserAccount(currentUser);
  const analyticsAndDefaults = useGetContractAnalytics();

  console.log("analyticsAndDefaults", analyticsAndDefaults);
  console.log("userAccount", userAccount);

  return (
    <VStack spacing={10} w={["full"]}>
      <HStack>
        <Heading>Dashboard</Heading>
        <Icon as={FcElectricalSensor} boxSize={16}></Icon>
      </HStack>
      <Wrap justify="center">
        {/* <MaxLimitComponent userAccount={userAccount} /> */}
        <BalancesComponent />
        {/* <BusinessComponent userAccount={userAccount} /> */}
        {/* <TeamComponent userAccount={userAccount} /> */}
        <IncomeComponent userAccount={userAccount} />
      </Wrap>
      <ReferralLinkComponent address={currentUser} />
    </VStack>
  );
};
