import { parseEther, zeroAddress } from 'viem';
import { chains, projectId } from '../providers/ProviderWeb3Modal';
import {
    BlockchainApiController,
    CaipNetwork,
    CaipAddress,
    NetworkController,
    AssetUtil,
    ConstantsUtil,
} from '@web3modal/core';

export const shortenAddress = (
    address: `0x${string}` | undefined,
    formatTo: number = 5,
) => {
    if (!address) {
        address = zeroAddress;
    }

    const string1 = address?.slice(0, formatTo);
    const divider = '...';
    const string2 = address?.slice(-formatTo);

    return string1 + divider + string2;
};

export const isChainSupported = (chainId: number | undefined) => {
    if (chainId) {
        for (let i = 0; i < chains.length; i++) {
            if (chains[i]?.id === chainId) {
                return true;
            }
        }
    }

    return false;
};

export interface BlockchainApiTokenPriceResponse {
    fungibles: {
        name: string;
        symbol: string;
        iconUrl: string;
        price: number;
    }[];
}

export const fecthTokenObjectWithPrice = async (
    addresses: CaipAddress[],
): Promise<BlockchainApiTokenPriceResponse> => {
    const price: BlockchainApiTokenPriceResponse =
        await BlockchainApiController?.fetchTokenPrice({
            projectId: projectId,
            addresses: addresses,
            currency: 'usd',
        });

    return price;
};

// export const test = async (
//   addresses: string[]
// ) => {
//   const price =
//     await NetworkController?.

//   return price;
// };

export const convertAddressTOCAIP10 = (address: string) => {
    const caipId = NetworkController?.state?.caipNetwork?.id;
    return `${caipId}:${address}`;
};

export const getWcChainIcon = (caipNetwork: CaipNetwork | undefined) => {
    return AssetUtil.getNetworkImage(caipNetwork);
};

export const isTokenAddressnative = (address: string) => {
    if (
        address?.toLowerCase() ===
        ConstantsUtil?.NATIVE_TOKEN_ADDRESS?.toLowerCase()
    ) {
        return true;
    }

    return false;
};
