import {
  FcApproval,
  FcGlobe,
  FcLock,
  FcMindMap,
  FcMultipleDevices,
  FcReadingEbook,
  FcSettings,
  FcTimeline,
} from 'react-icons/fc';

interface Target {
  description: string;
  icon: 'FcApproval' | 'FcGlobe' | 'FcLock' | 'FcMindMap' | 'FcMultipleDevices' | 'FcReadingEbook' | 'FcSettings' | 'FcTimeline';
}

interface RoadmapItem {
  year: number;
  quarter: string;
  targets: Target[];
}

const roadmapData: RoadmapItem[] = [
  {
    year: 2025,
    quarter: 'Q1',
    targets: [
      {
        description: 'Launch iFasst DEX Bot with Advanced Trading Strategies',
        icon: 'FcMindMap',
      },
      {
        description: 'Implement Automated Trading Algorithms',
        icon: 'FcSettings',
      },
      {
        description: 'Release User-Friendly Trading Interface',
        icon: 'FcMultipleDevices',
      },
    ],
  },
  {
    year: 2025,
    quarter: 'Q2',
    targets: [
      {
        description: 'Launch DeFi Lending & Borrowing Platform',
        icon: 'FcApproval',
      },
      {
        description: 'Implement Smart Contract-Based Loan System',
        icon: 'FcLock',
      },
      {
        description: 'Release Educational Resources for DeFi',
        icon: 'FcReadingEbook',
      },
    ],
  },
  {
    year: 2025,
    quarter: 'Q3',
    targets: [
      {
        description: 'Launch Fasst NFT Marketplace',
        icon: 'FcGlobe',
      },
      {
        description: 'Introduce NFT Minting & Trading Platform',
        icon: 'FcSettings',
      },
      {
        description: 'Release Creator Tools for NFT Artists',
        icon: 'FcMindMap',
      },
    ],
  },
  {
    year: 2025,
    quarter: 'Q4',
    targets: [
      {
        description: 'Launch Blockchain-Based Freelancer Platform',
        icon: 'FcMultipleDevices',
      },
      {
        description: 'Implement Smart Contract Payment System',
        icon: 'FcLock',
      },
      {
        description: 'Release Decentralized Video Marketplace',
        icon: 'FcGlobe',
      },
    ],
  },
  {
    year: 2026,
    quarter: 'Q1',
    targets: [
      {
        description: 'Launch Fasst Payments App',
        icon: 'FcApproval',
      },
      {
        description: 'Implement Global Payment Networks',
        icon: 'FcGlobe',
      },
      {
        description: 'Release Advanced Security Features',
        icon: 'FcLock',
      },
    ],
  },
  {
    year: 2026,
    quarter: 'Q2',
    targets: [
      {
        description: 'Scale Ecosystem to Global Markets',
        icon: 'FcGlobe',
      },
      {
        description: 'Launch Enterprise Solutions',
        icon: 'FcSettings',
      },
      {
        description: 'Implement Cross-Platform Integration',
        icon: 'FcMultipleDevices',
      },
    ],
  },
  {
    year: 2026,
    quarter: 'Q3',
    targets: [
      {
        description: 'Launch Community Governance Platform',
        icon: 'FcMindMap',
      },
      {
        description: 'Implement Strategic Partnerships',
        icon: 'FcApproval',
      },
      {
        description: 'Release Advanced Analytics Dashboard',
        icon: 'FcTimeline',
      },
    ],
  },
  {
    year: 2026,
    quarter: 'Q4',
    targets: [
      {
        description: 'Launch Financial Education Hub',
        icon: 'FcReadingEbook',
      },
      {
        description: 'Implement AI-Powered Financial Advisory',
        icon: 'FcMindMap',
      },
      {
        description: 'Release Wealth Management Tools',
        icon: 'FcSettings',
      },
    ],
  },
];

export default roadmapData;
