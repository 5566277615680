
        // Updated on 2024-12-22T06:48:18.987Z
        import { ContractDeploymentObjectsBlockchain } from '../types';
        export const IfasstStakingUpgradeableABI = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "target",
        "type": "address"
      }
    ],
    "name": "AddressEmptyCode",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "name": "CommonError",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "ERC1967InvalidImplementation",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ERC1967NonPayable",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "EnforcedPause",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ExpectedPause",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FailedInnerCall",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidInitialization",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidPrice",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotInitializing",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "UUPSUnauthorizedCallContext",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "slot",
        "type": "bytes32"
      }
    ],
    "name": "UUPSUnsupportedProxiableUUID",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "version",
        "type": "uint64"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "Paused",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "PreUnStakeFeesCharged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "stakingId",
        "type": "uint256"
      }
    ],
    "name": "Stake",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "rewardClaimedUSD",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "nativeValue",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "nativePrice",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokensIssued",
        "type": "uint256"
      }
    ],
    "name": "StakingRewardClaimed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "stakingId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "rewardClaimedUSD",
        "type": "uint256"
      }
    ],
    "name": "StakingRewardClaimedById",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "rewardPer",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "div",
        "type": "uint256"
      }
    ],
    "name": "StakingRewardPerUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "stakingId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "valueInUSD",
        "type": "uint256"
      }
    ],
    "name": "StakingValueUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "Unpaused",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "stakingId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "unstakeValueInUSD",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "nativeValue",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenValuePaid",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenAddressPaidIn",
        "type": "address"
      }
    ],
    "name": "UnstakeValuePaid",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "Upgraded",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "UPGRADE_INTERFACE_VERSION",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress_",
        "type": "address"
      }
    ],
    "name": "claimAllStakingsReward",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "claimedStakingReward",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user_",
        "type": "address"
      }
    ],
    "name": "claimDoubleStakingReward",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user_",
        "type": "address"
      }
    ],
    "name": "getActiveStakingValue",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "activeStakingValue",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "stakingId_",
        "type": "uint256"
      }
    ],
    "name": "getStakingAccountById",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "valueInUSD",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "startTime",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pendingReward",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "rewardClaimed",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "planId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "per",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "duration",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "maxLimitMul",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructInvestmentPlan",
            "name": "investmentPlan",
            "type": "tuple"
          }
        ],
        "internalType": "struct StructStaking",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getStakingContractAnalytics",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address[]",
            "name": "users",
            "type": "address[]"
          },
          {
            "internalType": "contract IVariables",
            "name": "variablesInterface",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "stakingIds",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalBusiness",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "minContribution",
            "type": "uint256"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "referralRate",
                "type": "tuple"
              },
              {
                "internalType": "uint256",
                "name": "levelCondition",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructReferralRates[]",
            "name": "referralRates",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "referral",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bot",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "staking",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "community",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bonanza",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "target",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "others",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructIncome",
            "name": "incomeDistributed",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken",
            "name": "nativeToken",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken",
            "name": "projectToken",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken",
            "name": "stableToken",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken[]",
            "name": "supportedTokensArray",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "tokensCollected",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "incomeDistributedInTokens",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "planId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "per",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "duration",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "maxLimitMul",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructInvestmentPlan[]",
            "name": "investmentPlansArray",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "createLiquidityPer",
                "type": "tuple"
              }
            ],
            "internalType": "struct StructCreateLiquidity",
            "name": "createLiquidity",
            "type": "tuple"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "rewardClaimFees",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "preUnStakeFees",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "providerFees",
                "type": "tuple"
              }
            ],
            "internalType": "struct StructFees",
            "name": "fees",
            "type": "tuple"
          }
        ],
        "internalType": "struct StructReturnsAnalyticsAndDefaults",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "stakingId_",
        "type": "uint256"
      }
    ],
    "name": "getStakingRewardById",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress_",
        "type": "address"
      }
    ],
    "name": "getStakingUserAccount",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "referrer",
            "type": "address"
          },
          {
            "internalType": "address[]",
            "name": "referees",
            "type": "address[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "user",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "level",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTeam[]",
            "name": "teams",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "selfBusiness",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "directBusiness",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "teamBusiness",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructBusiness",
            "name": "business",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "referral",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bot",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "staking",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "community",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bonanza",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "target",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "others",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructIncome",
            "name": "incomes",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "valueInUSD",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "startTime",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "pendingReward",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "rewardClaimed",
                "type": "uint256"
              },
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "owner",
                "type": "address"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "planId",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "duration",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "maxLimitMul",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructInvestmentPlan",
                "name": "investmentPlan",
                "type": "tuple"
              }
            ],
            "internalType": "struct StructStaking[]",
            "name": "stakings",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "investedWithTokens",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "incomeReceivedInTokens",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct StructUserAccountReturn",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddres_",
        "type": "address"
      }
    ],
    "name": "getUserStakingsReward",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "stakingRewards",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getVariablesContract",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "multiplier_",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "division_",
        "type": "uint256"
      }
    ],
    "name": "mulUserStakingPer",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pause",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "paused",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "proxiableUUID",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "stakingId_",
        "type": "uint256"
      }
    ],
    "name": "reArrange",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "totalUnStakeValue",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "per_",
        "type": "uint256"
      }
    ],
    "name": "setUserStakingPer",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "stakingId_",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "valueInUSD_",
        "type": "uint256"
      }
    ],
    "name": "setUserStakingValue",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "variablesContractAddress_",
        "type": "address"
      }
    ],
    "name": "setVariablesContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "valueInUSD_",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "planId_",
        "type": "uint256"
      }
    ],
    "name": "stake",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "stakingId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "unpause",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newImplementation",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "upgradeToAndCall",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
] as const;
        export const IfasstStakingUpgradeableDeploymentDetails: ContractDeploymentObjectsBlockchain = {
        [137]: {
            proxyAddress: '0x5E1D66C3b5F8EfB2e2FAf84b2d6C2Fd66aE83455',
            implementationAddress: '0xFc1f2F32f41ea99cc6dDC06A720F35Ce3Fc067b5',
            contractName: 'IfasstStakingUpgradeable',
            chainId: 137,
            deployer: '0x56f25711e383D13c8e43537966f37A730b080D4E',
            abi: IfasstStakingUpgradeableABI
        }
    }
