import { ColorModeScript, VStack } from '@chakra-ui/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './styles/global.css';
import * as serviceWorker from './serviceWorker';
import { RouterProvider } from 'react-router-dom';
import { NavigationRoutes } from './navigationRoutes/NavigationRoutes';

// Lazy load the providers and the router
const ProviderChakraUI = React.lazy(() =>
  import('./providers/ProviderChakraUI').then((module) => ({
    default: module.ProviderChakraUI
  }))
);
const ProviderWeb3Modal = React.lazy(() =>
  import('./providers/ProviderWeb3Modal').then((module) => ({
    default: module.ProviderWeb3Modal
  }))
);

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <React.Suspense fallback={<></>}>
    <ColorModeScript />
    <ProviderChakraUI>
      <ProviderWeb3Modal>
        <RouterProvider router={NavigationRoutes}></RouterProvider>
      </ProviderWeb3Modal>
    </ProviderChakraUI>
  </React.Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();
