import React from 'react';
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  keyframes,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { GiRoad } from 'react-icons/gi';
import {
  FcApproval,
  FcGlobe,
  FcLock,
  FcMindMap,
  FcMultipleDevices,
  FcReadingEbook,
  FcSettings,
  FcTimeline,
} from 'react-icons/fc';
import roadmapData from './roadmapData';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

// Icon mapping
const iconMap = {
  FcApproval,
  FcGlobe,
  FcLock,
  FcMindMap,
  FcMultipleDevices,
  FcReadingEbook,
  FcSettings,
  FcTimeline,
};

// Keyframes for the glow animation
const glowKeyframes = keyframes`
  0% { box-shadow: 0 0 10px -5px currentColor; }
  50% { box-shadow: 0 0 20px -5px currentColor; }
  100% { box-shadow: 0 0 10px -5px currentColor; }
`;

export const Roadmap = () => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 0.2], [100, 0]);

  const cardBg = useColorModeValue('whiteAlpha.900', 'blackAlpha.400');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const headingColor = useColorModeValue('gray.800', 'white');
  const timelineBg = useColorModeValue('blue.400', 'blue.500');
  const timelineDotBg = useColorModeValue('white', 'gray.800');
  const cardHoverBg = useColorModeValue('#FFFFFF', 'rgba(0, 0, 0, 0.6)');

  // Responsive values
  const isMobile = useBreakpointValue({ base: true, md: false });
  const timelineWidth = useBreakpointValue({ base: '2px', md: '3px' });
  const dotSize = useBreakpointValue({ base: '16px', md: '24px' });
  const cardPadding = useBreakpointValue({ base: 4, md: 8 });
  const cardSpacing = useBreakpointValue({ base: 8, md: 12 });
  const iconSize = useBreakpointValue({ base: 6, md: 7 });
  const headingSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }} px={{ base: 4, md: 6 }}>
      <VStack spacing={{ base: 10, md: 16 }} w="full">
        {/* Header Section */}
        <MotionFlex
          w="full"
          align="center"
          justify="center"
          gap={4}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <MotionHeading
            fontSize={['3xl', '4xl', '5xl', '6xl']}
            fontWeight="black"
            color={headingColor}
            textAlign="center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
          >
            Our Roadmap
          </MotionHeading>
          <Icon
            as={GiRoad}
            fontSize={['2xl', '3xl', '4xl', '5xl']}
            color="#4299E1"
          />
        </MotionFlex>

        {/* Timeline Section */}
        <MotionBox
          w="full"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Flex
            direction="column"
            gap={cardSpacing}
            position="relative"
            _before={{
              content: '""',
              position: 'absolute',
              left: { base: '20px', md: '50%' },
              width: timelineWidth,
              height: 'full',
              bg: timelineBg,
              transform: { base: 'none', md: 'translateX(-50%)' },
              borderRadius: 'full',
            }}
          >
            {roadmapData.map((data, index) => (
              <MotionFlex
                key={index}
                w="full"
                justify={{ base: 'flex-start', md: index % 2 === 0 ? 'flex-start' : 'flex-end' }}
                position="relative"
                pl={{ base: '50px', md: index % 2 === 0 ? 0 : '50%' }}
                pr={{ base: 0, md: index % 2 === 0 ? '50%' : 0 }}
                initial={{ opacity: 0, x: isMobile ? -50 : (index % 2 === 0 ? -100 : 100) }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <Box
                  position="absolute"
                  left={{ base: '20px', md: '50%' }}
                  top={{ base: '24px', md: '50%' }}
                  w={dotSize}
                  h={dotSize}
                  borderRadius="full"
                  bg={timelineDotBg}
                  transform={{ 
                    base: 'translateX(-50%)',
                    md: 'translate(-50%, -50%)'
                  }}
                  zIndex={2}
                  border="3px solid"
                  borderColor={timelineBg}
                  animation={`${glowKeyframes} 2s infinite`}
                  _before={{
                    content: '""',
                    position: 'absolute',
                    w: { base: '24px', md: '40px' },
                    h: { base: '24px', md: '40px' },
                    borderRadius: 'full',
                    bg: 'blue.200',
                    opacity: 0.3,
                    transform: { 
                      base: 'translate(-25%, -25%)',
                      md: 'translate(-22%, -22%)'
                    },
                  }}
                />

                <MotionBox
                  w="full"
                  maxW={{ base: 'full', md: '500px' }}
                  p={cardPadding}
                  borderRadius="2xl"
                  bg={cardBg}
                  borderWidth="1px"
                  borderColor={borderColor}
                  boxShadow="xl"
                  backdropFilter="blur(10px)"
                  position="relative"
                  whileHover={{ 
                    scale: isMobile ? 1 : 1.02,
                    backgroundColor: cardHoverBg,
                    transition: { duration: 0.2 }
                  }}
                  _before={{
                    content: '""',
                    position: 'absolute',
                    inset: '-1px',
                    borderRadius: '2xl',
                    padding: '2px',
                    background: 'linear-gradient(45deg, #4299E1, #9F7AEA)',
                    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    WebkitMaskComposite: 'xor',
                    maskComposite: 'exclude',
                  }}
                >
                  <VStack align="start" spacing={{ base: 4, md: 6 }}>
                    <MotionFlex 
                      justify="space-between" 
                      w="full" 
                      align="center"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      <Heading size={headingSize} color={headingColor}>
                        {data.quarter} {data.year}
                      </Heading>
                    </MotionFlex>
                    <VStack align="start" spacing={{ base: 3, md: 5 }} w="full">
                      {data.targets.map((target, idx) => (
                        <MotionFlex
                          key={idx}
                          gap={4}
                          align="center"
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                          transition={{ delay: idx * 0.2 }}
                          whileHover={{ x: isMobile ? 0 : 10 }}
                        >
                          <MotionBox
                            whileHover={{ 
                              rotate: isMobile ? 0 : 360,
                              transition: { duration: 0.5 }
                            }}
                          >
                            <Icon 
                              as={iconMap[target.icon as keyof typeof iconMap]} 
                              boxSize={iconSize} 
                              color="#4299E1"
                            />
                          </MotionBox>
                          <MotionText 
                            fontSize={{ base: 'sm', md: 'md' }}
                            whileHover={{ color: '#4299E1' }}
                            transition={{ duration: 0.2 }}
                          >
                            {target.description}
                          </MotionText>
                        </MotionFlex>
                      ))}
                    </VStack>
                  </VStack>
                </MotionBox>
              </MotionFlex>
            ))}
          </Flex>
        </MotionBox>
      </VStack>
    </Container>
  );
};
