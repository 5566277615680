import { useEffect } from 'react';
import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";

export const PrivacyPolicy = () => {

    return (
        <Flex w="full" gap={10} direction="column" px={5} py={5}>
            <Heading>
                Privacy Policy
            </Heading>
            <Text>
                At iFasst, we are committed to ensuring the privacy and protection of our users' information. As a decentralized staking platform, we prioritize your privacy by not collecting personally identifiable information such as names, addresses, or contact details. This Privacy Policy explains how we collect, use, and safeguard any non-personal data when you interact with our platform.
            </Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                1. Information We Collect
            </Heading>
            <Text size="md" mb={-7}>
                iFasst does not require users to complete KYC (Know Your Customer) procedures, so we do not collect personal information that could identify you. However, when you connect your wallet to our platform, we may collect the following types of non-personal information:
            </Text>
            <UnorderedList mb={-4}>
                <ListItem>Wallet Addresses: To enable staking, rewards distribution, and other transactions.</ListItem>
                <ListItem>Blockchain Transactions: Publicly available on the blockchain, including staking and reward-related data.</ListItem>
                <ListItem>Technical Data: Information such as IP addresses, device types, browser details, and operating system information may be collected to improve the user experience and security.</ListItem>
            </UnorderedList>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                2. How We Use the Information
            </Heading>
            <Text size="md" mb={-7}>
                The data we collect is used to:
            </Text>
            <UnorderedList size="md" mb={-7}>
                <ListItem>Facilitate your interactions with iFasst, including staking, reward distribution, and other services.</ListItem>
                <ListItem>Enhance platform performance and user experience through data analysis.</ListItem>
                <ListItem>Ensure platform security by monitoring for suspicious or fraudulent activities.</ListItem>
                <ListItem>Improve the platform based on usage trends and feedback.</ListItem>
            </UnorderedList>
            <Text mb={-4}>No personal information is ever required or stored by iFasst.</Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                3. Cookies and Tracking
            </Heading>
            <Text mb={-4}>
                To enhance your experience on iFasst, we may use cookies and similar tracking technologies. These cookies allow us to monitor platform usage, remember user preferences, and provide more efficient services. You can control or disable cookies through your browser settings, but please note that disabling cookies may affect platform functionality.
            </Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                4. Security
            </Heading>
            <Text mb={-4}>
                Security is central to iFasst. Our platform operates on the secure and transparent infrastructure of blockchain technology, which ensures that all transactions are immutable and visible on the public ledger. While we do not store personal data, we take additional measures to protect wallet addresses, transaction data, and user interactions. Users are encouraged to take personal precautions, such as enabling two-factor authentication on their wallets and using secure networks when accessing iFasst.
            </Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                5. Third-Party Links and Platforms
            </Heading>
            <Text mb={-4}>
                iFasst may include links to third-party platforms or decentralized applications (dApps) that operate independently of our platform. Please be aware that we have no control over these third-party sites and services, and their privacy practices may differ from ours. We recommend reviewing the privacy policies of any third-party services before engaging with them.
            </Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                6. Sharing of Information
            </Heading>
            <Text size="md" mb={-7}>
                We do not sell, share, or distribute user data to third parties for commercial purposes. The only instances in which we may share data are:
            </Text>
            <UnorderedList mb={-4}>
                <ListItem>Legal Compliance: If required by law, we may disclose information to meet legal obligations.</ListItem>
                <ListItem>Platform Security: In case of fraudulent activity or security threats, we may share necessary information with trusted security partners or law enforcement agencies to protect the platform.</ListItem>
            </UnorderedList>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                7. User Rights
            </Heading>
            <Text mb={-4}>
                As a decentralized platform, iFasst places control in the hands of the users. You are the sole owner of your wallet and transaction data. You may disconnect your wallet from iFasst at any time, and you have the right to clear any cookies or local storage associated with your interactions on the platform.
            </Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                8. Policy Updates
            </Heading>
            <Text mb={-4}>
                iFasst reserves the right to update this Privacy Policy from time to time to reflect changes in our practices, technology, or legal requirements. We will notify users of any material updates by updating the "Last Updated" date at the end of this policy. We encourage you to review this page regularly to stay informed about how we protect your privacy.
            </Text>

            <Heading size="md" mb={-7}>  {/* Set bottom margin here */}
                9. Contact Information
            </Heading>
            <Text mb={-4}>
                If you have any questions, concerns, or feedback regarding our Privacy Policy or data protection practices, please reach out to us via our support channels. While we take privacy seriously, users should always be vigilant in protecting their wallets and private keys when using decentralized platforms like iFasst.
            </Text>

            <Text mt={6}>Last Updated: October 6, 2024</Text>
        </Flex>
    );
};